import Vue from 'vue';
import Rails from '@rails/ujs';
import '../admin/public/bootstrap';
import LoadScript from '@common/components/LoadScript.vue';
import HeaderComponent from '../application/components/Header.vue';
import Swiper from '../application/Swiper.vue';
import MembersExplorer from '../application/components/members/Explorer.vue';
import '../admin/redactor/redactor';

require('../styles/application.scss');
require('flag-icons');

// Import all images <%= image_pack_tag 'rails.png' %>
require.context('../application/images', true);

Rails.start();

Vue.component('error-scroller', () => import('../application/components/ErrorScroller.vue'));
Vue.component('load-script', LoadScript);
Vue.component('header-component', HeaderComponent);
Vue.component('pdf-button', () => import('../application/components/PdfButton.vue'));
Vue.component('swiper', Swiper);
Vue.component('members-explorer', MembersExplorer);
Vue.component('multivalue-select', () =>
  import('../application/components/apply-as-member/MultivalueSelect.vue')
);
Vue.component('singlevalue-select', () =>
  import('../application/components/apply-as-member/SinglevalueSelect.vue')
);

window.addEventListener('DOMContentLoaded', function() {
  new Vue({
    el: '#js-wrapper'
  });

  $R('textarea.redactor', {
    plugins: ['counter'],
    breakline: true,
    buttons: ['bold', 'italic', 'deleted', 'lists', 'link'],
    minHeight: '72px',
    pasteBlockTags: ['ul', 'ol', 'li', 'p', 'figure', 'figcaption'],
    toolbarFixedTopOffset: 74
  });
});
