<template>
  <StaticStringInjector :string="placeholder" v-slot="{ string }">
    <Multiselect
      :value="value"
      :options="options"
      :searchable="true"
      :allowEmpty="true"
      :multiple="true"
      :placeholder="string"
      :showLabels="false"
      @input="$emit('input', $event)"
    ></Multiselect>
  </StaticStringInjector>
</template>

<script>
import Multiselect from 'vue-multiselect';
import StaticStringInjector from '../../../admin/statics/StaticStringInjector.vue';

export default {
  components: { Multiselect, StaticStringInjector },

  props: {
    value: { required: false, type: [String, Array], default: null },
    placeholder: { required: true, type: String },
    options: { type: Array, default: () => [] }
  }
};
</script>

<style lang="scss">
@import 'vue-multiselect/dist/vue-multiselect.min.css';
</style>

<style lang="scss" scoped>
::v-deep {
  .multiselect__tags {
    @apply bg-opacity-10 bg-primary;
    @apply rounded-sm;
    @apply min-h-[48px];
    @apply pt-3;
  }

  .multiselect__select {
    top: 5px;

    &::before {
      border-top-color: #08284f;
    }
  }

  .multiselect__tag {
    @apply bg-opacity-95 bg-primary;
  }

  .multiselect__tag-icon {
    &:hover {
      @apply bg-primary;
    }

    &::after {
      color: white;
    }
  }

  .multiselect__input,
  .multiselect__single,
  .multiselect__placeholder {
    @apply bg-transparent;
    @apply text-opacity-70 text-primary text-base font-medium;
  }
  .multiselect__input {
    line-height: unset !important;
    min-height: auto;
    margin: 0px !important;
    padding: 0px !important;
  }
  .multiselect__placeholder {
    padding: 0;
    margin: 0;
    color: #08284f;
  }
  .multiselect__content-wrapper {
    @apply rounded-lg;
    @apply mt-2;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border: 1px solid #f2f4f7;
  }

  .multiselect__element {
  }
  .multiselect__option {
    @apply text-base leading-6;
    color: #101828;

    &.multiselect__option--highlight {
      @apply bg-gray-lighter;
    }
  }
}
</style>
