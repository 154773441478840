<template>
  <th
    @click="$emit('clicked', name)"
    class="sortable-th"
    :class="{ asc: active && ascending, desc: active && descending }"
  >
    <slot />
  </th>
</template>

<script>
export default {
  props: {
    name: { required: true, type: String },
    currentSort: { required: true, type: Object }
  },

  computed: {
    active() {
      return this.currentSort.column === this.name;
    },

    ascending() {
      return this.currentSort.direction === 'asc';
    },

    descending() {
      return !this.ascending;
    }
  }
};
</script>

<style lang="scss" scoped>
th {
  padding: 0.5rem 1rem;
  @apply select-none;

  &.sortable-th {
    cursor: pointer;

    &::after {
      position: relative;
      display: inline-block;
      visibility: hidden;
      left: 10px;
      top: 0px;
      color: #999;
      margin-top: 4px;
      border-color: #999 transparent transparent;
      border-style: solid;
      border-width: 5px 5px 0;
      content: '';
      transition: transform 0.2s ease-in-out 0.2s;
    }

    &.asc::after {
      visibility: visible;
    }

    &.desc::after {
      visibility: visible;
      transform: rotate(180deg);
    }
  }
}
</style>
