<template>
  <div class="w-full flex items-center bg-light pl-4 h-12">
    <span v-html="icon"></span>
    <StaticStringInjector string="Search members" v-slot="{ string }">
      <input type="search" v-model="model" :placeholder="string" />
    </StaticStringInjector>
  </div>
</template>

<script>
import StaticStringInjector from '../../admin/statics/StaticStringInjector.vue';

const SearchIcon = `<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="a" maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="#fff"/></mask><g mask="url(#a)"><path fill="#08284F" d="M-13-13h50v50h-50z"/><mask id="b" maskUnits="userSpaceOnUse" x="-13" y="-13" width="50" height="50"><path fill="#fff" d="M-13-13h50v50h-50z"/></mask></g></svg>`;

export default {
  components: { StaticStringInjector },

  props: {
    value: { required: true, type: String }
  },

  data() {
    return {
      icon: SearchIcon
    };
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
input {
  @apply px-4 py-2 flex-1;
  @apply text-lg leading-relaxed text-primary;
  @apply outline-none;

  @apply rounded-sm outline-none bg-light;

  min-width: 1px;

  &::placeholder {
    @apply text-primary text-opacity-50;
  }
}
</style>
