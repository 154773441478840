<template>
  <div class="custom-swiper-container">
    <div ref="rootEl" class="js-swiper-container swiper-container" manual-init>
      <div class="swiper-wrapper">
        <slot />
      </div>
    </div>

    <div
      class="swiper-button-prev swiper-nav-btn programs-swiper-nav-btn js-swiper-prev"
      v-html="icons.leftArrow"
    />
    <div
      class="swiper-button-next swiper-nav-btn programs-swiper-nav-btn js-swiper-next"
      v-html="icons.rightArrow"
    />
  </div>
</template>

<script>
import Swiper from 'swiper';

const leftArrow =
  '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#FDDF01"><path d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm0-1C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm1.834-15.128a.5.5 0 0 0-.668-.744L7.753 12l5.413 4.872a.5.5 0 0 0 .668-.744L9.247 12l4.587-4.128z"/></svg>';
const rightArrow =
  '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#FDDF01"><path d="M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm0-1c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11zM10.166 7.872a.5.5 0 0 1 .668-.744L16.247 12l-5.413 4.872a.5.5 0 0 1-.668-.744L14.753 12l-4.587-4.128z"/></svg>';

const DEFAULT_BREAKPOINTS = {
  576: { slidesPerView: 2 },
  992: { slidesPerView: 3 },
  1200: { slidesPerView: 4 }
};

const DEFAULT_CONFIG = {
  watchOverflow: true,
  simulateTouch: false,
  slidesPerView: 1,
  spaceBetween: 32,
  speed: 500,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  }
};

export default {
  props: {
    breakpoints: {
      required: false,
      type: Object,
      default: () => {
        DEFAULT_BREAKPOINTS;
      }
    },

    config: { required: false, type: Object, default: () => ({}) }
  },

  data() {
    return {
      swiper: null,

      icons: {
        leftArrow,
        rightArrow
      }
    };
  },

  mounted() {
    this.swiper = new Swiper(this.$refs.rootEl, {
      ...DEFAULT_CONFIG,
      navigation: {
        nextEl: this.$el.querySelector('.js-swiper-next'),
        prevEl: this.$el.querySelector('.js-swiper-prev')
      },
      breakpoints: this.breakpoints,
      ...this.config
    });
  }
};
</script>

<style lang="scss" scoped>
.swiper-nav-btn svg {
  @apply block;
}
</style>
