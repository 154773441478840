<template>
  <div>
    <label class="text-primary hidden lg:block pb-2">
      <StaticString>Gender</StaticString>
    </label>
    <div class="filter-buttons" role="group">
      <button
        @click="toggle('Male')"
        type="button"
        class="btn btn-light-blue"
        :class="{ active: model === 'Male' }"
      >
        <StaticString>Male</StaticString>
      </button>
      <button
        @click="toggle('Female')"
        type="button"
        class="btn btn-light-blue"
        :class="{ active: model === 'Female' }"
      >
        <StaticString>Female</StaticString>
      </button>
    </div>
  </div>
</template>

<script>
import StaticString from '../../../admin/statics/StaticString.vue';

export default {
  components: { StaticString },
  props: {
    value: { type: String, default: null }
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  methods: {
    toggle(value) {
      if (this.model === value) {
        this.model = null;
      } else {
        this.model = value;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-buttons {
  @apply flex justify-between;

  .btn {
    @apply rounded-sm;
    @apply font-bold sm:text-sm;

    min-width: 110px;
    padding: 2px 20px 0;

    @screen lg {
      min-width: 120px;
    }
  }
}
</style>
