<template>
  <div class="flex flex-col lg:flex-row flex-nowrap gap-8 lg:items-start">
    <div class="lg:w-64 grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-1">
      <Search v-model="query" />
      <Dropdown v-model="countries" :options="countryOptions" placeholder="All countries" />
      <Dropdown v-model="regions" :options="regionOptions" placeholder="All regions" />
      <Dropdown v-model="languages" :options="languageOptions" placeholder="All languages" />
      <Dropdown v-model="topSkills" :options="topSkillsOptions" placeholder="All top skills" />
      <Dropdown
        v-model="expertises"
        :options="expertiseOptions"
        placeholder="All areas of expertise"
      />
      <Dropdown
        v-if="fullAccess"
        v-model="engagements"
        :options="engagementOptions"
        placeholder="All levels of engagement"
      />
      <Dropdown
        v-if="fullAccess"
        v-model="memberships"
        :options="membershipOptions"
        placeholder="All memberships"
      />
      <SexSelector v-if="fullAccess" v-model="sex" />
    </div>
    <div class="flex-1">
      <Members :members="visibleMembers" @sorted="onSortChange" />

      <Paginator v-model="page" :perPage="perPage" :length="filteredMembers.length" />
    </div>
  </div>
</template>

<script>
import Search from '../Search.vue';
import Dropdown from './Dropdown.vue';
import SexSelector from './SexSelector.vue';
import Members from './Members.vue';
import Paginator from './Paginator.vue';

export default {
  components: { Dropdown, Members, Search, SexSelector, Paginator },

  props: {
    members: { required: true, type: Array },
    fullAccess: { type: Boolean, default: false }
  },

  watch: {
    // Reset the pagination upon filter select
    query() {
      this.page = 1;
    },
    country() {
      this.page = 1;
    },
    region() {
      this.page = 1;
    },
    language() {
      this.page = 1;
    },
    expertise() {
      this.page = 1;
    },
    engagement() {
      this.page = 1;
    },
    membership() {
      this.page = 1;
    },
    topSkill() {
      this.page = 1;
    },
    sex() {
      this.page = 1;
    }
  },

  data() {
    return {
      page: 1,
      query: '',
      countries: [],
      regions: [],
      languages: [],
      expertises: [],
      engagements: [],
      memberships: [],
      topSkills: [],
      sex: null,
      sort: {
        column: '',
        direction: 'asc'
      }
    };
  },

  methods: {
    onSortChange({ column, direction }) {
      this.sort.column = column;
      this.sort.direction = direction;
    }
  },

  computed: {
    filteredMembers() {
      return new Filterable(this.members)
        .apply(this.query, (member, q) => member.searchable_string.includes(q.toLowerCase()))
        .apply(this.countries, (member, countries) => countries.includes(member.residence_country))
        .apply(this.regions, (member, regions) => regions.includes(member.region))
        .apply(this.languages, (member, languages) =>
          languages.every((item) => member.languages_list.includes(item))
        )
        .apply(this.expertises, (member, expertises) =>
          expertises.every((item) => member.expertise_areas.includes(item))
        )
        .apply(this.engagements, (member, engagements) =>
          engagements.includes(member.level_of_engagement)
        )
        .apply(this.topSkills, (member, skills) =>
          skills.every((item) => member.top_skills.includes(item))
        )
        .apply(this.memberships, (member, membership_category) =>
          membership_category.includes(member.membership)
        )
        .apply(this.sex, (member, sex) => member.sex_tag === sex)
        .done();
    },

    visibleMembers() {
      return new Filterable(this.filteredMembers)
        .sort(this.sort)
        .paginate(this.page, this.perPage)
        .done();
    },

    countryOptions() {
      return unique(this.members, (member) => member.residence_country);
    },

    regionOptions() {
      return unique(this.filteredMembers, (member) => member.region);
    },

    languageOptions() {
      return unique(this.filteredMembers, (member) => member.languages_list);
    },

    topSkillsOptions() {
      return unique(this.filteredMembers, (member) => member.top_skills);
    },

    expertiseOptions() {
      return unique(this.filteredMembers, (member) => member.expertise_areas);
    },

    engagementOptions() {
      return unique(this.filteredMembers, (member) => member.level_of_engagement);
    },

    membershipOptions() {
      return unique(this.filteredMembers, (member) => member.membership).filter(item => item !== ''); // filter empty strings
    },

    perPage() {
      return 10;
    }
  }
};

class Filterable {
  constructor(data) {
    this._data = data;
  }

  apply(filterValue, callback) {
    if (!filterValue) return this;
    if (!filterValue.length) return this;

    return new Filterable(this._data.filter((item) => callback(item, filterValue)));
  }

  sort({ column, direction }) {
    return new Filterable(
      this._data.sort((left, right) => sortHandler(left, right, column, direction))
    );
  }

  paginate(page, perPage) {
    const start = (page - 1) * perPage;
    return new Filterable(this._data.slice(start, start + perPage));
  }

  done() {
    return this._data;
  }
}

const sortHandler = (left, right, column, direction) => {
  const firstValue = left[column] ? left[column].toLowerCase() : '';
  const secondValue = right[column] ? right[column].toLowerCase() : '';

  let comparisonResult = firstValue.localeCompare(secondValue);

  if (direction === 'desc') {
    // Invert the result when sorting in descending order
    comparisonResult *= -1;
  }

  return comparisonResult;
};

const unique = (data, callback) => {
  const values = data.flatMap((record) => {
    const result = callback(record);
    return Array.isArray(result) ? result : [result];
  });

  const uniqueValues = [...new Set(values)];

  uniqueValues.sort();

  return uniqueValues.filter((val) => val !== null);
};
</script>
