<template>
  <div class="overflow-x-auto">
    <table class="members-grid">
      <MembersHeader @sorted="$emit('sorted', $event)" />
      <tbody>
        <tr v-for="member in members" :key="member.id">
          <td class="members-column" data-attribute="name">
            <a :href="member.url" class="flex items-center gap-3 member-image-name">
              <img v-if="member.image" :src="member.image" class="rounded-full" />
              <div v-else class="member-image-placeholder">
                {{ member.initials }}
              </div>

              <span class="text-primary font-medium member-name">{{ member.name }}</span>
            </a>
          </td>
          <td class="members-column" data-attribute="city">
            {{ member.city }}
          </td>
          <td class="members-column" data-attribute="country">
            <span
              v-if="member.residence_country_code"
              :class="`mr-1 fi fi-${member.residence_country_code.toLowerCase()}`"
            />
            {{ member.residence_country }}
          </td>
          <td class="members-column" data-attribute="region">
            {{ member.region }}
          </td>
          <td class="members-column" data-attribute="languages">
            {{ (member.languages_list || []).join(', ') }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import MembersHeader from './MembersHeader.vue';

export default {
  components: { MembersHeader },

  props: {
    members: { required: true, type: Array }
  }
};
</script>

<style lang="scss" scoped>
.members-grid {
  width: 100%;

  tbody {
    tr {
      border-bottom: solid 1px #eaecf0;
    }

    td {
      @apply text-sm;
      padding: 1.5rem 1rem;
    }

    td:not(:first-child) {
      @apply font-normal leading-5;
      @apply text-sm text-gray-dark text-opacity-70;
    }
  }
}

.table-cell-image {
  @apply rounded-full;

  width: 40px;
  height: 40px;
  vertical-align: top;
  border-style: solid;
}

.member-image-name {
  &:hover {
    .member-name {
      @apply underline;
    }
  }
}

.member-image-placeholder {
  @apply flex items-center justify-center flex-shrink-0;
  @apply bg-gray-lighter rounded-full w-10 h-10;
  @apply text-[#475467]  font-bold;
  @apply select-none;
}
</style>
