<template>
  <thead>
    <tr>
      <ThCell @clicked="toggleSort" name="name" :currentSort="currentSort">
        Name
      </ThCell>
      <ThCell @clicked="toggleSort" name="city" :currentSort="currentSort">
        City
      </ThCell>
      <ThCell @clicked="toggleSort" name="residence_country" :currentSort="currentSort">
        Country of Residence
      </ThCell>
      <ThCell @clicked="toggleSort" name="region" :currentSort="currentSort">
        Region
      </ThCell>
      <th>Languages</th>
    </tr>
  </thead>
</template>

<script>
import ThCell from './MembersHeaderTh.vue';

export default {
  components: { ThCell },

  data() {
    return {
      sortColumn: '',
      sortAscending: true
    };
  },

  computed: {
    currentSort() {
      return { column: this.sortColumn, direction: this.sortAscending ? 'asc' : 'desc' };
    }
  },

  methods: {
    toggleSort(column) {
      if (this.sortColumn === column) {
        // Already sorting by this column just toggle the direction
        this.sortAscending = !this.sortAscending;
      } else {
        // Sorting by new column
        this.sortColumn = column;
        this.sortAscending = true;
      }

      this.$emit('sorted', this.currentSort);
    }
  }
};
</script>

<style lang="scss" scoped>
thead {
  @apply text-gray-dark text-xs font-medium;
  @apply text-left bg-gray-lighter;
  border-bottom: solid 1px #eaecf0;

  tr {
    height: 3rem;
  }
}
</style>
