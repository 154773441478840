<template>
  <div class="pagination-container">
    <div>
      <button @click="goTo(page - 1)" type="button" class="arrow " :disabled="isFirstPage">
        <img src="../../images/icons/arrow_back.svg" alt="Previous" />
        Previous
      </button>
    </div>
    <div class="pages">
      <button
        v-for="p in leftPages"
        :key="p"
        @click="goTo(p)"
        :class="{ active: p === page }"
        type="button"
      >
        {{ p }}
      </button>
      <span v-if="rightPages.length" class="separator">...</span>
      <button
        v-for="p in rightPages"
        :key="p"
        @click="goTo(p)"
        :class="{ active: p === page }"
        type="button"
      >
        {{ p }}
      </button>
    </div>
    <div class="sm:hidden">{{ page }}/{{ totalPages }}</div>
    <div>
      <button @click="goTo(page + 1)" type="button" class="arrow " :disabled="isLastPage">
        Next
        <img src="../../images/icons/arrow_forward.svg" alt="Next" />
      </button>
    </div>
  </div>
</template>

<script>
const MIN_PAGES_TO_PAGINATE = 5;

export default {
  props: {
    value: { required: true, type: Number },
    length: { required: true, type: Number },
    perPage: { required: true, type: Number }
  },

  computed: {
    page: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },

    totalPages() {
      return Math.ceil((this.length * 1.0) / this.perPage);
    },

    isFirstPage() {
      return this.page === 1;
    },

    isLastPage() {
      return this.page === this.totalPages;
    },

    isCurrentPageAfterMiddle() {
      return this.page > this.totalPages / 2;
    },

    leftPages() {
      if (this.totalPages < MIN_PAGES_TO_PAGINATE) {
        return this.allPages;
      }

      if (this.isCurrentPageAfterMiddle) {
        return [1];
      }

      return this.sliceNeighboringPages();
    },

    rightPages() {
      if (this.totalPages < MIN_PAGES_TO_PAGINATE) {
        return [];
      }

      if (!this.isCurrentPageAfterMiddle) {
        return [this.totalPages];
      }

      return this.sliceNeighboringPages();
    },

    allPages() {
      return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    }
  },

  methods: {
    goTo(page) {
      this.page = page;
    },

    sliceNeighboringPages() {
      const totalSlice = 5;
      const halfSlice = Math.ceil((totalSlice * 1.0) / 2);

      if (this.page < halfSlice) {
        return this.allPages.slice(0, totalSlice);
      }
      if (this.page > this.totalPages - halfSlice) {
        return this.allPages.slice(this.totalPages - totalSlice, this.totalPages);
      }

      return this.allPages.slice(this.page - halfSlice, this.page + halfSlice - 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination-container {
  @apply font-medium text-sm text-primary;
  @apply flex justify-between mt-4 items-center;
  @apply select-none;
}

.separator {
  @apply flex items-center;
  @apply px-3;
  color: #808080;
}

.pages {
  @apply hidden sm:flex;
  @apply w-1/2 justify-center gap-1;

  button {
    @apply w-10 h-10;
    @apply flex items-center justify-center;
    color: #808080;
    @apply rounded-full;

    &.active {
      @apply text-primary;
      @apply bg-opacity-10 bg-blue;
    }
  }
}

.arrow {
  @apply flex items-center justify-evenly;
  min-width: 100px;

  &:disabled {
    @apply text-gray;
    @apply cursor-not-allowed;

    img {
      @apply opacity-20;
    }
  }
}
</style>
