<script>
export default {
  data() {
    return {
      mobileMenuOpened: false
    };
  },

  mounted() {
    window.addEventListener('resize', this.onResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize: function() {
      if (window.innerWidth >= 992 && this.mobileMenuOpened) {
        this.mobileMenuOpened = false;
      }
    }
  }
};
</script>
